<template>
  <div>시스템 > 활동이력</div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

/**
 * client
 */
import client from "api-client";

export default {
  name: "SystemActvty",
  components: {
    /**
     * components
     */
  },
  data() {
    /**
     * data
     */
    return {};
  },
  created() {
    /**
     * created
     */
    this.$Progress.start();

    console.log(client);
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
